import { Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function StatusAlert(props) {
  return (
    <div
      style={{
        flex: props.flex,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        ...props.sx,
      }}
    >
      <Typography
        variant="body2"
        align="center"
        color="white"
        sx={{ fontSize: props.sx.fontSize }}
      >
        {props.text}
      </Typography>
    </div>
  );
}

export function PendingStatusAlert(props) {
  const theme = useTheme();
  return (
    <StatusAlert
      text={props.abbreviated ? "PENDING" : "This trial is currently pending"}
      {...props}
      sx={{
        backgroundColor: theme.palette.warning.contrastText,
        fontSize: props.fontSize ? props.fontSize : 14,
        width: "90px",
        height: "30px",
        marginLeft: "auto",
        flexShrink: 0,
        marginRight: "6px",
      }}
    />
  );
}

export function OnHoldStatusAlert(props) {
  const theme = useTheme();
  return (
    <StatusAlert
      text={props.abbreviated ? "ON HOLD" : "This trial is currently on hold"}
      {...props}
      sx={{
        fontSize: props.fontSize ? props.fontSize : 14,
        width: "90px",
        height: "30px",
        marginLeft: "auto",
        flexShrink: 0,
        marginRight: "6px",
        backgroundColor: theme.palette.error.main,
      }}
    />
  );
}
