import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";

function Title(props) {
  return (
    <Typography component="h4" color="primary" style={props.style}>
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;
