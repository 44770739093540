import { ArrowBackIos } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, IconButton, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { getAllPathsV2 } from "SearchNodes/SearchNodes";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiServiceContext } from "../../Context/GlobalContext";
import AlertDialogModalComponent from "../AlertDialogModalComponent";
import Item from "../Item";
import LoadingComponent from "../LoadingComponent";
import NativeMultiSelect from "../NativeMultiSelect";
import ContactPickerComponent from "./ContactPickerComponent";

const dbDateFormat = "MMMM YYYY";

function convertDateToMoment(date) {
  if (!date) {
    return null;
  }
  const temp = moment(date, dbDateFormat);
  return moment(temp, "MM/DD/YYYY");
}

export default function ModifyTrialPage(props) {
  const apiService = useContext(ApiServiceContext);
  let location = useLocation();
  const navigate = useNavigate();

  const { state } = useLocation();

  const [cancerGroups, setCancerGroups] = useState(null);
  const [visibleError, setVisibleError] = useState(null);
  const [draftTrial, setDraftTrial] = useState(null);
  const [sites, setSites] = useState(null);

  const [newCriteria, setNewCriteria] = useState(null);
  const [newPath, setNewPath] = useState(null);

  const isNewTrialPage = location.pathname.split("/")[1] === "insertTrial";

  useEffect(() => {
    const sitesPromise = apiService.getAllSitesWithContacts();
    const searchPathsPromise = apiService.getAllSearchPaths();

    let draftTrialPromise;
    if (isNewTrialPage) {
      const nctId = location.pathname.split("/")[2];
      draftTrialPromise = apiService.getAuthoritativeTrial(nctId);
    } else {
      const trialId = state.trialId;
      draftTrialPromise = apiService.getTrialForModification(trialId);
    }

    Promise.all([draftTrialPromise, sitesPromise, searchPathsPromise])
      .then((responses) => {
        setCancerGroups(responses[2].data);
        if (isNewTrialPage) {
          setDraftTrial(responses[0].data.getAuthoritativeTrial.trial);
        } else {
          const trial = { ...responses[0].data.getTrial };
          // Map sponsor, criteria
          trial.criteria = trial.criteria
            ? trial.criteria.map((c) => c.criteria)
            : null;
          trial.sponsor = trial.sponsor ? trial.sponsor.sponsor : null;
          setDraftTrial(trial);
        }
        const sites = responses[1].data.listSites;
        setSites(sites);
      })
      .catch((error) => {
        if (error.message) {
          setVisibleError(error.message);
        } else {
          setVisibleError(JSON.stringify(error));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (draftTrial === null) {
    return <LoadingComponent />;
  }

  const criteria = draftTrial.criteria ? draftTrial.criteria : [];

  function handleBackClicked() {
    navigate(-1);
  }

  function didPressDeleteCritera(e) {
    draftTrial.criteria.splice(e.currentTarget.id, 1);
    const trialCopy = JSON.parse(JSON.stringify(draftTrial));
    setDraftTrial(trialCopy);
  }

  function didPressAddCritera(e) {
    if (newCriteria === null || newCriteria === "") {
      return;
    }
    const criteriaCopy = draftTrial.criteria;
    criteriaCopy.push(newCriteria);
    draftTrial.criteria = criteriaCopy;
    const trialCopy = JSON.parse(JSON.stringify(draftTrial));
    setNewCriteria(null);
    setDraftTrial(trialCopy);
  }

  function newCriteriaUpdated(e) {
    setNewCriteria(e.target.value);
  }

  function didPressRemovePath(e) {
    const pathID = e.currentTarget.id;
    const trialCopy = JSON.parse(JSON.stringify(draftTrial));

    trialCopy.path_ids = trialCopy.path_ids.filter((p) => p !== pathID);
    setDraftTrial(trialCopy);
  }

  function didPressAddPath(e) {
    if (newPath === null || newPath === "") {
      return;
    }

    const trialCopy = JSON.parse(JSON.stringify(draftTrial));
    if (
      trialCopy.path_ids &&
      trialCopy.path_ids.filter((p) => p === newPath).length !== 0
    ) {
      return;
    }

    var path_ids = trialCopy.path_ids ? trialCopy.path_ids : [];
    path_ids.push(newPath);
    trialCopy.path_ids = path_ids;
    setDraftTrial(trialCopy);
    setNewPath(null);
  }

  function handlePathSelected(e) {
    setNewPath(e.target.value);
  }

  function didPressAddTrials(e) {
    const apiTrial = {};
    apiTrial.number = draftTrial.number;
    apiTrial.one_line_description = draftTrial.one_line_description;
    apiTrial.formal_title = draftTrial.formal_title;
    apiTrial.readable_title = draftTrial.readable_title;
    apiTrial.simple_description = draftTrial.simple_description;

    apiTrial.shorthand_name = draftTrial.shorthand_name;
    apiTrial.nickname = draftTrial.nickname;

    apiTrial.start_date = draftTrial.start_date;

    apiTrial.status = draftTrial.status;

    apiTrial.phase = draftTrial.phase;
    apiTrial.characteristics = draftTrial.characteristics;
    apiTrial.measurement = draftTrial.measurement;

    apiTrial.criteria = draftTrial.criteria;
    apiTrial.sponsor = draftTrial.sponsor;
    apiTrial.characteristics = draftTrial.characteristics;
    if (draftTrial.contacts) {
      apiTrial.contactIDs = draftTrial.contacts.map((contact) => contact.id);
    }

    apiTrial.pathIDs = draftTrial.path_ids;

    apiService
      .updateTrial({ trial: apiTrial, is_new: isNewTrialPage })
      .then(async (result) => {
        navigate("/trialDetails/" + result.data.updateTrial.id);
      })
      .catch((e) => {
        if (e.message) {
          setVisibleError(e.message);
        } else {
          setVisibleError(JSON.stringify(e));
        }
      });
  }

  function didPressDeleteTrial() {
    const input = {
      input: {
        id: draftTrial.id,
      },
    };
    apiService
      .deleteTrial(input)
      .then(async (result) => {
        await apiService.resetCache();
        navigate("/dashboard/");
      })
      .catch((e) => {
        if (e.message) {
          setVisibleError(e.message);
        } else {
          setVisibleError(JSON.stringify(e));
        }
      });
  }

  function handleChange(e) {
    const trialCopy = JSON.parse(JSON.stringify(draftTrial));
    if (e.target.name.slice(0, 9) === "criteria-") {
      const criteriaIndex = parseInt(e.target.name.slice(9, 10));
      criteria[criteriaIndex] = trialCopy[e.target.name] = e.target.value;
      trialCopy.criteria = criteria;
    } else {
      trialCopy[e.target.name] = e.target.value;
    }

    setDraftTrial(trialCopy);
  }

  function handleDateChange(e) {
    if (!e.isValid()) {
      return;
    }
    const trialCopy = JSON.parse(JSON.stringify(draftTrial));
    trialCopy.start_date = e.format(dbDateFormat);
    setDraftTrial(trialCopy);
  }

  function handleContactChange(e) {
    const contactId = e.target.id;
    const isChecked = e.target.checked;
    const trialCopy = JSON.parse(JSON.stringify(draftTrial));

    if (!isChecked) {
      // Remove contact from the trial
      const contacts = trialCopy.contacts.filter((contact) => {
        return contact.id !== contactId;
      });
      trialCopy.contacts = contacts;
    } else {
      // Add the contact to the trial
      const contactObject = sites
        .flatMap((site) => site.contacts)
        .filter((contact) => {
          return contact.id === contactId;
        })[0];
      let contacts = trialCopy.contacts ? trialCopy.contacts : [];
      contacts.push(contactObject);
      trialCopy.contacts = contacts;
    }
    setDraftTrial(trialCopy);
  }

  function handleDialogClose() {
    setVisibleError(null);
  }

  let all_paths = getAllPathsV2(cancerGroups).map((item) => {
    return {
      itemName: item.pathID,
      itemDescription: item.pathDescription,
    };
  });
  return (
    <Box
      minWidth="350px"
      sx={{
        "& .MuiTextField-root": { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <AlertDialogModalComponent
        open={visibleError !== null}
        onClose={handleDialogClose}
        error={visibleError}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Item sx={{ width: "100%", paddingBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              color="primary"
              size="small"
              onClick={handleBackClicked}
              sx={{
                position: "absolute",
              }}
            >
              <ArrowBackIos />
            </IconButton>
            <Typography variant="body1" margin={"auto"}>
              NCT: {draftTrial.number}
            </Typography>
            <IconButton
              color="primary"
              size="small"
              onClick={didPressDeleteTrial}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </div>
        </Item>
        <TextField
          fullWidth
          required
          multiline
          id="one_line_description"
          name="one_line_description"
          label="What is this trial?"
          value={draftTrial.one_line_description ?? ""}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          multiline
          id="simple_description"
          name="simple_description"
          label="Simple Description"
          value={draftTrial.simple_description ?? ""}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          multiline
          id="formal_title"
          name="formal_title"
          label="Formal Title"
          value={draftTrial.formal_title ?? ""}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          multiline
          id="readable_title"
          name="readable_title"
          label="Readable Title"
          value={draftTrial.readable_title ?? ""}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          multiline
          id="shorthand_name"
          name="shorthand_name"
          label="Shorthand Name"
          value={draftTrial.shorthand_name ?? ""}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          multiline
          id="nickname"
          name="nickname"
          label="Nickname"
          value={draftTrial.nickname ?? ""}
          onChange={handleChange}
        />
        <DatePicker
          id="start_date"
          name="start_date"
          label="Start Date"
          format="MM/DD/YYYY"
          value={convertDateToMoment(draftTrial.start_date) ?? ""}
          onChange={handleDateChange}
          sx={{
            width: "100%",
          }}
        />

        <TextField
          required
          fullWidth
          multiline
          id="phase"
          name="phase"
          label="Phase"
          value={draftTrial.phase ?? ""}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          multiline
          id="characteristics"
          name="characteristics"
          label="Characteristics"
          value={draftTrial.characteristics ?? ""}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          multiline
          id="sponsor"
          name="sponsor"
          label="Sponsor"
          value={draftTrial.sponsor ?? ""}
          onChange={handleChange}
        />
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            fullWidth
            labelId="status"
            id="status"
            name="status"
            value={draftTrial.status ?? ""}
            label="Status"
            onChange={handleChange}
          >
            <MenuItem key="open" value="Open">
              Open
            </MenuItem>
            <MenuItem key="hold" value="Hold">
              Hold
            </MenuItem>
            <MenuItem key="closed" value="Closed">
              Closed
            </MenuItem>
            <MenuItem key="pending" value="Pending">
              Pending
            </MenuItem>
          </Select>
        </FormControl>
        <div
          style={{
            width: "100%",
          }}
        >
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">Paths</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel>Path</InputLabel>
                  <NativeMultiSelect
                    id="new-path"
                    name="new-path"
                    value={newPath ?? ""}
                    label="Add Path"
                    onChange={handlePathSelected}
                    items={all_paths}
                  />
                </FormControl>
                <IconButton
                  onClick={didPressAddPath}
                  disabled={newPath === null || newPath === ""}
                >
                  <AddIcon />
                </IconButton>
              </div>
              {draftTrial.path_ids
                ? draftTrial.path_ids.map((p) => {
                    return (
                      <div
                        key={p}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <Typography>Trial Path: {p}</Typography>
                        <IconButton onClick={didPressRemovePath} id={p}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    );
                  })
                : null}
            </AccordionDetails>
          </Accordion>
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">Criteria</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <TextField
                  required
                  fullWidth
                  multiline
                  id="add-criteria"
                  label="New Criteria"
                  value={newCriteria ? newCriteria : ""}
                  onChange={newCriteriaUpdated}
                />
                <IconButton
                  onClick={didPressAddCritera}
                  disabled={newCriteria === null || newCriteria === ""}
                >
                  <AddIcon />
                </IconButton>
              </div>
              {criteria.map((c) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                    key={criteria.indexOf(c)}
                  >
                    <TextField
                      required
                      fullWidth
                      multiline
                      id={"criteria-" + criteria.indexOf(c)}
                      name={"criteria-" + criteria.indexOf(c)}
                      label="Criteria"
                      onChange={handleChange}
                      value={c ?? ""}
                    />
                    <IconButton
                      onClick={didPressDeleteCritera}
                      id={criteria.indexOf(c)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">Sites/Contact</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              ></div>
              {sites.map((site) => {
                return (
                  <ContactPickerComponent
                    key={site.id}
                    site={site}
                    selectedContacts={draftTrial.contacts}
                    handleContactChange={handleContactChange}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>
        <Button variant="contained" onClick={didPressAddTrials}>
          {isNewTrialPage ? "Add Trial" : "Save"}
        </Button>
      </div>
    </Box>
  );
}
