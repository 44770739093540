import { Navigate } from "react-router-dom";

import ProtectedRouteService from "./Helpers/ProtectedRouteService";

export default function LoggedInOnlyRoute(props) {
  const loginRedirect = <Navigate to="/login" replace={true} />;

  return (
    <ProtectedRouteService
      {...props}
      isLoggedInOnlyRoute
      redirect={loginRedirect}
    />
  );
}
