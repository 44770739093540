import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import * as React from "react";
import Title from "../Title";
import TrialRow from "./TrialRow";

export default function TrialTable(props) {
  const tableElement =
    props.trials && props.trials.length > 0 ? (
      <Table size="small" sx={{}}>
        <TableBody
          sx={{
            padding: "6px 0px 6px 0px",
          }}
        >
          {props.trials.map((trial) => (
            <TrialRow key={trial.id} {...props} trial={trial} />
          ))}
        </TableBody>
      </Table>
    ) : (
      <Typography variant="body1">No Trials</Typography>
    );

  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      {tableElement}
    </React.Fragment>
  );
}
