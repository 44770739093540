import { gql } from "@apollo/client";

import GraphQLClient from "./GraphQLClient";
import RESTClient from "./RESTClient";
import TokenRefreshClient from "./TokenRefreshClient";

const ALL_TRIAL_FIELDS_FRAGMENT = gql`
  fragment trialFields on Trial {
    id
    number
    nickname
    one_line_description
    status
    is_favorite
    status
    formal_title
    shorthand_name
    sponsor {
      id
      sponsor
    }
    sites {
      id
      name
      contacts {
        id
      }
    }
    contacts {
      id
      name
      email
      phone
    }
    trial_views
    criteria {
      id
      criteria
    }
    characteristics
    simple_description
    comments {
      content
      publish_time
      author_name
      is_author
    }
  }
`;

export default function APIService(tokenService) {
  const tokenRefreshClient = TokenRefreshClient(tokenService);
  const graphqlClient = GraphQLClient(tokenService, tokenRefreshClient);
  const restClient = RESTClient(tokenService, tokenRefreshClient);

  async function resetCache() {
    await graphqlClient.resetStore();
  }

  function fetchAllTrials() {
    return graphqlClient.query({
      query: gql`
        query listTrials {
          listTrials {
            id
            number
            nickname
            shorthand_name
            one_line_description
            status
            is_favorite
          }
        }
      `,
    });
  }

  function getTrialForModification(id) {
    return graphqlClient.query({
      query: gql`
        query getTrial($id: ID!) {
          getTrial(id: $id) {
            id
            number
            nickname
            one_line_description
            readable_title
            shorthand_name
            start_date
            status
            phase
            status
            formal_title
            contacts {
              id
              name
            }
            sponsor {
              sponsor
            }
            sites {
              id
              name
              contacts {
                id
                name
                email
                phone
              }
            }
            criteria {
              criteria
            }
            characteristics
            simple_description
            path_ids
          }
        }
      `,
      variables: {
        id: id,
      },
    });
  }

  function getTrial(id) {
    return graphqlClient.query({
      query: gql`
        query getTrial($id: ID!) {
          getTrial(id: $id) {
            id
            number
            nickname
            one_line_description
            status
            is_favorite
            status
            formal_title
            shorthand_name
            sponsor {
              sponsor
            }
            sites {
              id
              name
              contacts {
                id
              }
            }
            contacts {
              id
              name
              email
              phone
            }
            trial_views
            criteria {
              criteria
            }
            characteristics
            simple_description
            comments {
              id
              content
              publish_time
              author_name
              is_author
            }
          }
        }
      `,
      variables: {
        id: id,
      },
    });
  }

  function getAuthoritativeTrial(nctID) {
    return graphqlClient.query({
      query: gql`
        query getAuthoritativeTrial($nctID: ID!) {
          getAuthoritativeTrial(nctID: $nctID) {
            trial {
              number
              nickname
              one_line_description
              status
              readable_title
              simple_description
              nickname
              formal_title
              start_date
              phase
              criteria
              characteristics
              simple_description
            }
          }
        }
      `,
      variables: {
        nctID: nctID,
      },
    });
  }

  function searchTrials(path_id) {
    return graphqlClient.query({
      query: gql`
        query searchTrials($path_id: ID!) {
          searchTrials(path_id: $path_id) {
            id
            number
            nickname
            phase
            shorthand_name
            one_line_description
            status
            is_favorite
            num_paths
          }
        }
      `,
      variables: {
        path_id: path_id,
      },
    });
  }

  function getAllSites() {
    return graphqlClient.query({
      query: gql`
        query GetAllSites {
          listSites {
            id
            name
            contacts {
              id
            }
          }
        }
      `,
    });
  }

  function getAllSitesWithContacts() {
    return graphqlClient.query({
      query: gql`
        query GetAllSites {
          listSites {
            id
            name
            contacts {
              id
              name
              email
              phone
            }
          }
        }
      `,
    });
  }

  function getAllContacts() {
    return graphqlClient.query({
      query: gql`
        query GetAllContacts {
          listContacts {
            id
            name
            email
            phone
            site_id
          }
        }
      `,
    });
  }

  function basicuserinfo() {
    return graphqlClient.query({
      query: gql`
        query BasicUserInfo {
          currentUser {
            username
            first_name
            last_name
            is_active
          }
        }
      `,
    });
  }

  function getAccountDetails() {
    return graphqlClient.query({
      query: gql`
        query BasicUserInfo {
          currentUser {
            username
            first_name
            last_name
            email
            is_active
            date_registered
            trials_viewed
          }
        }
      `,
    });
  }

  function sendTrialEmail(data) {
    return graphqlClient.mutate({
      mutation: gql`
        mutation SendTrialEmail($trial_id: ID!, $contact_email: String!) {
          sendTrialEmail(trial_id: $trial_id, contact_email: $contact_email) {
            success
            errors
          }
        }
      `,
      variables: {
        trial_id: data.trial_id,
        contact_email: data.contact_email,
      },
    });
  }

  function updateTrial(data) {
    return graphqlClient.mutate({
      mutation: gql`
        ${ALL_TRIAL_FIELDS_FRAGMENT}
        mutation UpdateTrial($trial: UpdateTrialInput!, $is_new: Boolean!) {
          updateTrial(is_new: $is_new, trial: $trial) {
            ...trialFields
          }
        }
      `,
      variables: {
        trial: data.trial,
        is_new: data.is_new,
      },
    });
  }

  function deleteTrial(data) {
    return graphqlClient.mutate({
      mutation: gql`
        mutation DeleteTrial($input: DeleteTrialInput!) {
          deleteTrial(input: $input) {
            success
            errors
          }
        }
      `,
      variables: {
        input: data.input,
        is_new: data.is_new,
      },
    });
  }

  function updateContact(contactInput, site_id) {
    let input = {};
    if (site_id) {
      input.site_id = site_id;
    }
    if (contactInput?.id) {
      input.id = contactInput.id;
    }
    if (contactInput?.name) {
      input.name = contactInput.name;
    }
    if (contactInput?.phone) {
      input.phone = contactInput.phone;
    }
    if (contactInput?.email) {
      input.email = contactInput.email;
    }
    return graphqlClient.mutate({
      mutation: gql`
        mutation UpdateContact($contact: UpdateContactInput!) {
          updateContact(contact: $contact) {
            success
            contact {
              id
              name
              phone
              email
              site_id
            }
            errors
          }
        }
      `,
      variables: {
        contact: input,
      },
    });
  }

  function deleteContact(contactId) {
    return graphqlClient.mutate({
      mutation: gql`
        mutation DeleteContact($contact: DeleteContactInput!) {
          deleteContact(contact: $contact) {
            success
            errors
          }
        }
      `,
      variables: {
        contact: {
          id: contactId,
        },
      },
    });
  }

  function addComment(trial_id, comment_content) {
    return graphqlClient.mutate({
      mutation: gql`
        mutation AddComment($trial_id: ID!, $comment_content: String!) {
          addComment(trial_id: $trial_id, comment_content: $comment_content) {
            id
            comments {
              content
              publish_time
            }
          }
        }
      `,
      variables: {
        trial_id: trial_id,
        comment_content: comment_content,
      },
    });
  }

  function updateComment(comment_id, comment_content) {
    return graphqlClient.mutate({
      mutation: gql`
        mutation UpdateComment($comment_id: ID!, $comment_content: String!) {
          updateComment(
            comment_id: $comment_id
            comment_content: $comment_content
          ) {
            id
            comments {
              content
              publish_time
            }
          }
        }
      `,
      variables: {
        comment_id_id: comment_id,
        comment_content: comment_content,
      },
    });
  }

  function deleteComment(comment_id) {
    return graphqlClient.mutate({
      mutation: gql`
        mutation DeleteComment($comment_id: ID!) {
          deleteComment(comment_id: $comment_id) {
            id
            comments {
              content
              publish_time
            }
          }
        }
      `,
      variables: {
        comment_id: comment_id,
      },
    });
  }

  function getAllSearchPaths() {
    return restClient.get("/api/allSearchPaths");
  }

  function insertTestTrial() {
    return restClient.post("api/inserttesttrial");
  }

  function deleteAllTrials() {
    return restClient.post("/api/deleteAllTrials");
  }

  function updateTrials(data) {
    return restClient.post("/api/updatetrials", {
      fileContent: data,
    });
  }

  function login(data) {
    return restClient.post("/api/login", {
      username: data.get("username"),
      password: data.get("password"),
    });
  }

  function logout(data) {
    return restClient.get("/api/logout");
  }

  function register(data) {
    return restClient.post("/api/register", {
      email: data.get("email"),
    });
  }

  function addemail(data) {
    return restClient.post("/api/addemail", {
      email: data.email,
    });
  }

  function deleteEmail(data) {
    return restClient.post("/api/deleteemail", {
      email: data.email,
    });
  }

  function deleteAccount(data) {
    return restClient.post("/api/deleteaccount", {
      email: data.email,
      username: data.username,
    });
  }

  function confirmAccount(data) {
    return restClient.post("/api/confirm_email", data);
  }

  function allAccounts() {
    return restClient.get("/api/allaccounts");
  }

  function allEmails() {
    return restClient.get("/api/whitelistedemails");
  }

  function verifyToken() {
    return restClient.get("/api/tokenverify");
  }

  function resetPassword(data) {
    return restClient.post("/api/resetpassword", {
      email: data.get("email"),
    });
  }

  function updatePassword(data) {
    return restClient.post("/api/updatepassword", data);
  }

  return {
    verifyToken: verifyToken,
    login: login,
    logout: logout,
    register: register,
    confirmAccount: confirmAccount,
    fetchAllTrials: fetchAllTrials,
    getTrial: getTrial,
    deleteAllTrials: deleteAllTrials,
    insertTestTrial: insertTestTrial,
    allEmails: allEmails,
    searchTrials: searchTrials,
    allAccounts: allAccounts,
    addemail: addemail,
    deleteEmail: deleteEmail,
    deleteAccount: deleteAccount,
    updateTrials: updateTrials,
    basicuserinfo: basicuserinfo,
    resetPassword: resetPassword,
    updatePassword: updatePassword,
    getAccountDetails: getAccountDetails,
    sendTrialEmail: sendTrialEmail,
    getAuthoritativeTrial,
    updateTrial,
    resetCache,
    getAllSites,
    getAllSitesWithContacts,
    getTrialForModification,
    deleteTrial,
    updateContact,
    deleteContact,
    getAllContacts,
    addComment,
    updateComment,
    deleteComment,
    getAllSearchPaths,
  };
}
