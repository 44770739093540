import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Alert, AlertTitle } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Copyright from "../Copyright";

import { Grid, Link } from "@mui/material";
import { useContext, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { ApiServiceContext } from "../../Context/GlobalContext";
import { LoadingStatus } from "../../Helpers/LoadableObject";
import LoadingComponent from "../LoadingComponent";

function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

export default function ConfirmAccountComponent(props) {
  const [urlParams] = useSearchParams();
  const [alertWrapper, setAlertWrapper] = useState({
    loadingStatus: null,
  });
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const [didSubmit, setDidSubmit] = useState(false);
  const apiService = useContext(ApiServiceContext);

  const token = urlParams.get("token");
  if (token === null) {
    return <Navigate to="/login" replace />;
  }

  function handleInputChanged(e) {
    const targetName = e.target.name;
    let value = e.target.value;

    if (targetName === "phone") {
      value = formatPhoneNumber(e.target.value);
    }
    const updatedInput = JSON.parse(JSON.stringify(input));
    updatedInput[targetName] = value;
    setInput(updatedInput);

    if (didSubmit || targetName === "password" || targetName === "password2") {
      validate(updatedInput, didSubmit);
    }
  }

  function handleSubmit(event) {
    setDidSubmit(true);
    if (validate(input, true)) {
      sendConfirmAccountRequest(event);
    }
  }

  function validateRequiredField(input, fieldName, errors) {
    const hasField =
      fieldName in input &&
      input[fieldName] !== null &&
      input[fieldName] !== undefined &&
      input[fieldName].length > 0;

    errors[fieldName] = !hasField;
    return hasField;
  }

  function validate(input, allFields) {
    const updatedErrors = JSON.parse(JSON.stringify(errors));

    if (allFields) {
      validateRequiredField(input, "first_name", updatedErrors);
      validateRequiredField(input, "last_name", updatedErrors);
      const validPhone =
        input.phone !== undefined &&
        input.phone !== null &&
        input.phone.length === 14;
      updatedErrors["phone"] = !validPhone;
      validateRequiredField(input, "username", updatedErrors);
      validateRequiredField(input, "password", updatedErrors);
    }

    if (
      input.password !== null &&
      input.password !== undefined &&
      input.password.length > 0
    ) {
      const matching_pass = input.password === input.password2;
      updatedErrors["passwords_match"] = !matching_pass;
    } else if (
      // No password
      input.password2 !== null &&
      input.password2 !== undefined &&
      input.password2.length > 0
    ) {
      const matching_pass = input.password === input.password2;
      updatedErrors["passwords_match"] = !matching_pass;
    } else {
      updatedErrors["passwords_match"] = false;
    }

    setErrors(updatedErrors);

    return (
      Object.values(updatedErrors).filter((val) => val === true).length === 0
    );
  }

  function sendConfirmAccountRequest(event) {
    setAlertWrapper({
      loadingStatus: LoadingStatus.LOADING,
      alert: <LoadingComponent />,
    });
    event.preventDefault();

    const data = {
      ...input,
      token: token,
    };

    apiService
      .confirmAccount(data)
      .then(() => {
        setAlertWrapper({
          loadingStatus: LoadingStatus.SUCCESS,
          alert: (
            <Alert severity="success">
              <AlertTitle>Success!</AlertTitle>
              You have completed setting up this account with the username: {""}
              {data.username}
              <br />
              <Link href="/login" variant="body2">
                Click Here to login
              </Link>
            </Alert>
          ),
        });
      })
      .catch((error) => {
        setAlertWrapper({
          loadingStatus: LoadingStatus.ERROR,
          alert: (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              An Error occurred setting up this account:
              {`\n`}
              {error.response.data.error_message}
              {`\n`}
              Please try again.
            </Alert>
          ),
        });
      });
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create Account
        </Typography>
        {alertWrapper.alert}
        {alertWrapper.loadingStatus === LoadingStatus.SUCCESS ||
        alertWrapper.loadingStatus === LoadingStatus.LOADING ? null : (
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  id="first_name"
                  label="First Name"
                  name="first_name"
                  value={input.first_name}
                  onChange={handleInputChanged}
                  color={errors.first_name ? "error" : null}
                  error={errors.first_name ? true : false}
                  helperText={
                    errors.first_name ? "A first name is required" : null
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  value={input.last_name}
                  onChange={handleInputChanged}
                  color={errors.last_name ? "error" : null}
                  error={errors.last_name ? true : false}
                  helperText={
                    errors.last_name ? "A last name is required" : null
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  value={input.phone}
                  onChange={handleInputChanged}
                  inputMode="tel"
                  color={errors.phone ? "error" : null}
                  error={errors.phone ? true : false}
                  helperText={
                    errors.phone ? "A valid phone number is required" : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  value={input.username}
                  onChange={handleInputChanged}
                  color={errors.username ? "error" : null}
                  error={errors.username ? true : false}
                  helperText={errors.username ? "A username is required" : null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="password"
                  label="Password"
                  type="password"
                  name="password"
                  onChange={handleInputChanged}
                  color={errors.password ? "error" : null}
                  error={errors.password ? true : false}
                  helperText={errors.username ? "A password is required" : null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="password2"
                  label="Confirm Password"
                  type="password"
                  name="password2"
                  onChange={handleInputChanged}
                  color={
                    errors.password2 || errors.passwords_match ? "error" : null
                  }
                  error={
                    errors.password2 || errors.passwords_match ? true : false
                  }
                  helperText={
                    errors.password2
                      ? "A password is required"
                      : errors.passwords_match
                      ? "Passwords must match"
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Create Account
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
