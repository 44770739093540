import jwt_decode from "jwt-decode";

function TokenService() {
  const accessTokenStorageKey = "accessToken";
  const refreshTokenStorageKey = "refreshToken";

  function getAccessToken() {
    return localStorage.getItem(accessTokenStorageKey);
  }
  function getRefreshToken() {
    return localStorage.getItem(refreshTokenStorageKey);
  }

  function getRoles() {
    const decoded = jwt_decode(getAccessToken());
    const json_roles = decoded["roles"];
    return json_roles;
  }

  function setTokens(accessToken, refreshToken) {
    localStorage.setItem(refreshTokenStorageKey, refreshToken);
    localStorage.setItem(accessTokenStorageKey, accessToken);
  }

  function clearTokens() {
    localStorage.removeItem(refreshTokenStorageKey);
    localStorage.removeItem(accessTokenStorageKey);
  }

  return {
    getAccessToken,
    getRefreshToken,
    setTokens,
    getRoles,
    clearTokens,
  };
}

export default TokenService;
