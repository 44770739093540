import { ApolloClient, createHttpLink, fromPromise } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { InvalidationPolicyCache } from "apollo-invalidation-policies";

export default function GraphQLClient(tokenService, tokenRefreshClient) {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_HOSTNAME + "/api/graphql",
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${tokenService.getAccessToken()}`,
      },
    };
  });

  const errorLink = onError(
    ({ networkError, graphQLErrors, response, operation, forward }) => {
      if (
        networkError &&
        (networkError.statusCode === 401 || networkError.statusCode === 422)
      ) {
        // access token expired.
        // refresh it, and try the operation again
        return fromPromise(tokenRefreshClient.updateAccessToken()).flatMap(() =>
          forward(operation)
        );
      }
    }
  );

  const graphqlClient = new ApolloClient({
    cache: new InvalidationPolicyCache({
      invalidationPolicies: {
        timeToLive: 300000, // 5 min in ms
      },
    }),
    link: errorLink.concat(authLink).concat(httpLink),
    credentials: "include",
  });

  return graphqlClient;
}
