import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import useSessionStorage from "use-session-storage-state";
import {
  LoadingStatus,
  createLoadableObject,
} from "../../Helpers/LoadableObject";
import LoadingComponent from "../LoadingComponent";
import TrialTable from "./TrialTable";
import { useContext } from "react";
import { ApiServiceContext } from "../../Context/GlobalContext";

const Statuses = ["Open", "Closed", "Hold", "Pending"];

export default function DashboardContent(props) {
  const apiService = useContext(ApiServiceContext);

  const [allTrials, setAllTrials] = useState(createLoadableObject());
  const [filterText, setFilterText] = useSessionStorage("search-text-filter", {
    defaultValue: null,
  });
  const [statuses, setStatuses] = useSessionStorage("status-filter", {
    defaultValue: Statuses,
  });

  useEffect(() => {
    handleFetchAllTrials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFetchAllTrials() {
    setAllTrials({
      ...allTrials,
      loadingStatus: LoadingStatus.LOADING,
    });
    apiService
      .fetchAllTrials()
      .then((result) => {
        setAllTrials({
          loadingStatus: LoadingStatus.SUCCESS,
          trials: result.data.listTrials,
        });
      })
      .catch(() => {
        setAllTrials({
          loadingStatus: LoadingStatus.ERROR,
          trials: null,
        });
      });
  }

  function handleStatusSelected(event) {
    setStatuses(event.target.value);
  }

  function handleFavoriteTrialUpdated(trial) {
    handleFetchAllTrials();
  }

  function handleFilterUpdated(e) {
    const filterText = e.target.value.toLowerCase();
    if (filterText === null || filterText === "") {
      setFilterText(null);
    } else {
      setFilterText(filterText);
    }
  }

  function trialsToDisplay() {
    var filteredByText = allTrials.trials;
    if (!filteredByText) {
      return filteredByText;
    }
    if (filterText !== null && filterText !== "") {
      filteredByText = allTrials.trials.filter((t) => {
        return (
          (t.nickname && t.nickname.toLowerCase().includes(filterText)) ||
          (t.one_line_description &&
            t.one_line_description.toLowerCase().includes(filterText))
        );
      });
    }
    filteredByText = filteredByText.filter((t) => {
      return statuses.includes(t.status);
    });
    return filteredByText;
  }

  if (allTrials.loadingStatus === LoadingStatus.LOADING) {
    return <LoadingComponent />;
  }

  const filteredTrials = trialsToDisplay();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <TextField
        placeholder="Filter"
        onChange={handleFilterUpdated}
        value={filterText ?? ""}
        style={{
          height: "100%",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="primary" />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      <FormControl>
        <InputLabel>Statuses</InputLabel>
        <Select
          multiple
          label="statuses"
          value={statuses}
          onChange={handleStatusSelected}
          renderValue={(selected) => selected.join(", ")}
        >
          {Statuses.map((status) => (
            <MenuItem key={status} value={status}>
              <Checkbox checked={statuses.indexOf(status) > -1} />
              <ListItemText primary={status} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <TrialTable
          {...props}
          title="All Trials"
          trials={filteredTrials}
          setSelectedTrial={props.setSelectedTrial}
          handleFavoriteTrialUpdated={handleFavoriteTrialUpdated}
        />
      </Paper>
    </div>
  );
}
