import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useContext, useEffect, useState } from "react";
import {
  ApiServiceContext,
  TokenServiceContext,
} from "../../Context/GlobalContext";
import Title from "../Title";
import LoadingComponent from "../LoadingComponent";
import ContactsComponentRow from "./ContactsComponentRow";
export default function ContactsComponent(props) {
  const apiService = useContext(ApiServiceContext);
  const tokenService = useContext(TokenServiceContext);

  const [isLoading, setIsLoading] = useState(true);
  const [sites, setSites] = useState(null);
  const [contacts, setContacts] = useState(null);

  const roles = tokenService.getRoles();
  const is_admin = roles ? roles.includes("admin") : false;

  useEffect(() => {
    handleFetchAllContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFetchAllContacts() {
    setIsLoading(true);

    const sitesPromite = apiService.getAllSites();
    const contactsPromise = apiService.getAllContacts();
    Promise.all([sitesPromite, contactsPromise])
      .then((responses) => {
        setSites(responses[0].data.listSites);
        setContacts(responses[1].data.listContacts);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function contactUpdated(contact, added) {
    if (!added) {
      handleFetchAllContacts();
    } else {
      let updatedContacts = [...contacts];
      updatedContacts.push(contact);
      setContacts(updatedContacts);
    }
  }

  function contactDeleted(contact) {
    let updatedContacts = [...contacts];
    const contactIndex = updatedContacts.findIndex((c) => c.id === contact.id);
    updatedContacts.splice(contactIndex, 1);
    setContacts(updatedContacts);
  }

  function contactsForSite(site_id) {
    return contacts.filter((c) => c.site_id === site_id);
  }

  function contactRowsForSite(site_id) {
    const contactList = contactsForSite(site_id);
    return (
      <TableBody>
        {contactList.map((contact) => {
          const contactObject = {
            id: contact.id,
            name: contact.name,
            phone: contact.phone,
            email: contact.email,
          };
          return (
            <ContactsComponentRow
              key={contact.id}
              editable={is_admin}
              contact={contactObject}
              contactUpdated={contactUpdated}
              contactDeleted={contactDeleted}
            />
          );
        })}
        {!is_admin ? null : (
          <ContactsComponentRow
            key={"newContact-" + contactList.length}
            editable={is_admin}
            siteID={site_id}
            newContact
            contactUpdated={contactUpdated}
          />
        )}
      </TableBody>
    );
  }

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!sites) {
    return <></>;
  }

  return sites.map((site) => {
    return (
      <div key={sites.indexOf(site)}>
        <Title>{site.name}</Title>
        <TableContainer component={Paper}>
          <Table sx={{ tableLayout: "fixed" }}>
            <colgroup>
              <col style={{ width: "30%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "40%" }} />
              <col style={{ width: "10%" }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Phone</TableCell>
                {is_admin ? <TableCell align="right"></TableCell> : null}
              </TableRow>
            </TableHead>
            {contactRowsForSite(site.id)}
          </Table>
        </TableContainer>
      </div>
    );
  });
}
