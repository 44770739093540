import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import APIService from "Service/APIService";
import {
  ConfirmAccountComponent,
  LoginComponent,
  RegisterComponent,
  ResetPasswordComponent,
  UpdatePasswordComponent,
} from "Components/Account";
import RootContent from "./Components/RootContent";
import LoggedInOnlyRoute from "./LoggedInOnlyRoute";
import TokenService from "./Service/TokenService";

import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useState } from "react";
import {
  ApiServiceContext,
  TokenServiceContext,
} from "./Context/GlobalContext";
import LoggedOutOnlyRoute from "./LoggedOutOnlyRoute";
import { appTheme } from "./theme";

function App() {
  const tokenService = TokenService();
  const [tokenServiceState] = useState(tokenService);
  const [apiService] = useState(APIService(tokenService));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <ApiServiceContext.Provider value={apiService}>
          <TokenServiceContext.Provider value={tokenServiceState}>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/login"
                  element={
                    <LoggedOutOnlyRoute>
                      <LoginComponent />
                    </LoggedOutOnlyRoute>
                  }
                />
                <Route
                  path="/register"
                  element={
                    <LoggedOutOnlyRoute>
                      <RegisterComponent />
                    </LoggedOutOnlyRoute>
                  }
                />
                <Route
                  path="/resetpassword"
                  element={
                    <LoggedOutOnlyRoute>
                      <ResetPasswordComponent />
                    </LoggedOutOnlyRoute>
                  }
                />
                <Route
                  path="/updatepassword"
                  element={
                    <LoggedOutOnlyRoute>
                      <UpdatePasswordComponent />
                    </LoggedOutOnlyRoute>
                  }
                />
                <Route
                  path="/confirmaccount"
                  element={
                    <LoggedOutOnlyRoute>
                      <ConfirmAccountComponent />
                    </LoggedOutOnlyRoute>
                  }
                />

                <Route
                  path="/*"
                  element={
                    <LoggedInOnlyRoute>
                      <RootContent />
                    </LoggedInOnlyRoute>
                  }
                ></Route>
              </Routes>
            </BrowserRouter>
          </TokenServiceContext.Provider>
        </ApiServiceContext.Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
