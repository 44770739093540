import axios from "axios";

export default function RESTClient(tokenService, tokenRefreshClient) {
  const axiosClient = axios.create();

  axiosClient.interceptors.request.use(
    (config) => {
      config.baseURL = process.env.REACT_APP_API_HOSTNAME;
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${tokenService.getAccessToken()}`,
      };
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosClient.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error.response.status !== 401 && error.response.status !== 422) {
        return Promise.reject(error);
      }

      const originalConfig = error.config;
      if (originalConfig._isRetry) {
        return Promise.reject(error);
      }

      if (originalConfig.url === "/api/login") {
        return Promise.reject(error);
      }
      await tokenRefreshClient.updateAccessToken();
      originalConfig._isRetry = true;
      return axiosClient(originalConfig);
    }
  );

  return axiosClient;
}
