import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { useContext, useState } from "react";
import { ApiServiceContext } from "../Context/GlobalContext";
import DropzoneComponent from "./DropzoneComponent";

const ResultType = {
  Success: "Success",
  Fail: "Fail",
};

function CreateResult(type, value) {
  return {
    resultType: type,
    resultValue: value,
  };
}

export default function UploadTrialDebugComponent(props) {
  const apiService = useContext(ApiServiceContext);
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = useState([]);
  const [result, setResult] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
    if (event.target.id === "dismiss_result") {
      setResult(null);
      window.location.reload(false);
    }
  };

  const handleUpdateTestTrials = () => {
    setOpen(false);

    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      apiService
        .updateTrials(reader.result)
        .then((response) => {
          const count = response.data["trials"];
          setResult(CreateResult(ResultType.Success, count));
        })
        .catch((error) => {
          setResult(CreateResult(ResultType.Fail, null));
        });
    };
    reader.readAsText(files[0]);
  };

  const selectedFilePreview =
    files.length === 0
      ? null
      : files.map((file) => (
          <div key={file.name}>
            <p>You've uploaded: {file.name}</p>
          </div>
        ));

  const confirmationDialog = (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Update Trial Data?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Updating trial data will remove all existing trials, and replace them
          with the contents of the attached csv.
          <br />
          Are you sure you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpdateTestTrials} autoFocus>
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );

  const resultDialog = () => {
    if (result === null) {
      return null;
    }
    if (result.resultType === ResultType.Success) {
      return (
        <Dialog
          open={true}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Trial Data Uploaded"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {result.resultValue} trials were successfully updated.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} id="dismiss_result">
              Dismiss
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    if (result.resultType === ResultType.Fail) {
      return (
        <Dialog
          open={true}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Trial Data Update Failed"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Failed to update trial data
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} id="dismiss_result">
              Dismiss
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

  return (
    <div>
      <DropzoneComponent setFiles={setFiles}></DropzoneComponent>
      {selectedFilePreview}
      {selectedFilePreview === null ? null : (
        <Button variant="outlined" onClick={handleClickOpen}>
          Upload Trial Data
        </Button>
      )}
      {confirmationDialog}
      {resultDialog()}
    </div>
  );
}
