import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Title from "../Title";
import { cancerGroups, getNodeFromNodeName } from "SearchNodes/SearchNodes";
import ValidatedDropDownV2 from "./ValidatedDropDownV2";

export default function TrialSearchFormComponent(props) {
  function handleDidSubmitSearch() {
    props.searchTrials(props.searchModel);
  }

  function dropdownSelected(group, nodeName) {
    let groupIter = props.cancerGroups;
    let searchModelIdx = 0;

    // Walk through the entire search model, finding the current path
    let newSearchModel = [];
    while (groupIter != null && searchModelIdx >= 0) {
      if (groupIter.groupId !== group) {
        const searchModelNode = props.searchModel[searchModelIdx];
        if (searchModelNode.groupId !== groupIter.groupId) {
          // error condition
          console.log("this is bad...");
          searchModelIdx = -1;
        } else {
          // We haven't found the matching node yet,
          // continue on to the next child.
          newSearchModel.push({
            groupId: groupIter.groupId,
            nodeName: searchModelNode.nodeName,
          });
          searchModelIdx++;
          const nextNode = getNodeFromNodeName(
            groupIter,
            searchModelNode.nodeName
          );
          groupIter = nextNode.childGroup;
        }
      } else {
        // This is the end of the tree, so terminate here.
        const node = getNodeFromNodeName(groupIter, nodeName);
        let searchModelNode = {
          groupId: groupIter.groupId,
          nodeName: nodeName,
        };
        if (node !== null && node.pathID !== undefined) {
          searchModelNode.pathID = node.pathID;
        }
        newSearchModel.push(searchModelNode);
        searchModelIdx = -1;
      }
    }

    props.setSearchModel(newSearchModel);
  }

  function createDropdowns() {
    let dropDowns = [];

    let pathID = undefined;
    let selectedIdx = 0;
    let groupIter = props.cancerGroups;

    while (groupIter) {
      let selectedNode = null;
      if (props.searchModel && selectedIdx < props.searchModel.length) {
        selectedNode = getNodeFromNodeName(
          groupIter,
          props.searchModel[selectedIdx].nodeName
        );
      }

      pathID = selectedNode !== null ? selectedNode.pathID : undefined;
      dropDowns.push(
        <ValidatedDropDownV2
          key={groupIter.groupName}
          group={groupIter}
          value={selectedNode ? selectedNode.nodeName : null}
          dropdownSelected={dropdownSelected}
        />
      );
      selectedIdx++;
      groupIter = selectedNode ? selectedNode.childGroup : null;
    }
    return {
      dropDowns,
      pathID,
    };
  }

  const dropDowns = createDropdowns();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          maxWidth: "500px",
          flexGrow: 1,
        }}
      >
        <Title
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Find A Trial
        </Title>
        {dropDowns.dropDowns.map((c) => c)}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            disabled={dropDowns.pathID === undefined}
            onClick={handleDidSubmitSearch}
          >
            Search
          </Button>
        </div>
      </Paper>
    </div>
  );
}
