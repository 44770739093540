import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function ContactInfoRow(props) {
  return (
    <TableRow>
      <TableCell style={{ width: "5%" }}>{props.iconComponent}</TableCell>
      <TableCell>{props.itemProp}</TableCell>
    </TableRow>
  );
}
