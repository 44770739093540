import { useContext, useEffect, useState } from "react";
import { LoadingStatus, createLoadableObject } from "./LoadableObject";
import {
  ApiServiceContext,
  TokenServiceContext,
} from "../Context/GlobalContext";

export default function ProtectedRouteService(props) {
  const [result, setResult] = useState(createLoadableObject());

  const apiService = useContext(ApiServiceContext);
  const tokenService = useContext(TokenServiceContext);

  useEffect(() => {
    const accessToken = tokenService.getAccessToken();
    const refreshToken = tokenService.getRefreshToken();

    if (!accessToken) {
      setResult({
        loadingStatus: LoadingStatus.ERROR,
        isTokenError: true,
      });
      return;
    }

    if (!refreshToken) {
      setResult({
        loadingStatus: LoadingStatus.ERROR,
        isTokenError: true,
      });
      return;
    }

    if (!accessToken || !refreshToken) {
      setResult({
        loadingStatus: LoadingStatus.ERROR,
        isTokenError: true,
      });
    } else if (props.isLoggedInOnlyRoute) {
      apiService
        .verifyToken()
        .then(() => {
          setResult({
            loadingStatus: LoadingStatus.SUCCESS,
          });
        })
        .catch((error) => {
          let isTokenError = false;
          if (error.response.status === 401 || error.response.status === 422) {
            isTokenError = true;
          }
          setResult({
            loadingStatus: LoadingStatus.ERROR,
            isTokenError: isTokenError,
          });
        });
    } else {
      setResult({
        ...result,
        loadingStatus: LoadingStatus.SUCCESS,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (result.loadingStatus === LoadingStatus.LOADING) {
    return null;
  }

  if (result.loadingStatus === LoadingStatus.ERROR && result.isTokenError) {
    if (props.isLoggedInOnlyRoute) {
      return props.redirect;
    }
    return props.children;
  }

  if (props.isLoggedInOnlyRoute) {
    return props.children;
  }
  return props.redirect;
}
