export default function getUsername(userObject) {
  if (userObject === null || userObject === undefined) {
    return null;
  }
  return (
    userObject.data.currentUser.first_name +
    " " +
    userObject.data.currentUser.last_name
  );
}
