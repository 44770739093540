export function getNodeFromNodeName(group, nodeName) {
  if (nodeName === null) {
    return null;
  }

  for (var node of group.nodes) {
    if (node.nodeName === nodeName) {
      return node;
    }
  }
}

export function getAllPathsV2(cancerGroups) {
  let result = getAllPathsV2Helper(cancerGroups, "");
  result.sort((a, b) => {
    return a.pathID - b.pathID;
  });
  return result;
}

function getAllPathsV2Helper(group, pathDescription) {
  const nodes = group.nodes;
  let result = [];
  for (let node of nodes) {
    let fullPathName;
    if (pathDescription !== "") {
      fullPathName = pathDescription + " -> " + node.nodeName;
    } else {
      fullPathName = node.nodeName;
    }

    if (node.pathID) {
      result.push({
        pathID: node.pathID,
        pathDescription: fullPathName,
      });
    } else if (node.childGroup) {
      const childPaths = getAllPathsV2Helper(node.childGroup, fullPathName);
      result.push(...childPaths);
    }
  }
  return result;
}
