import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { ApiServiceContext } from "../Context/GlobalContext";
import Title from "./Title";
import UploadTrialDebugComponent from "./UploadTrialDebugComponent";

const TrialDialogState = {
  Delete: "Delete",
  Insert: "Insert",
};

export default function DebugOptionsComponent(props) {
  const apiService = useContext(ApiServiceContext);
  const [accounts, setAccounts] = useState(null);
  const [whitelistedEmails, setWhitelistedEmails] = useState(null);

  const [emailToAdd, setEmailToAdd] = useState(null);
  const [emailDialog, setEmailDialog] = useState(null);
  const [trialStateDialog, setTrialStateDialog] = useState(null);

  useEffect(() => {
    fetchAllAccounts();
    fetchAllEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = (event) => {
    if (event.currentTarget.id === "delete_trials") {
      setTrialStateDialog(TrialDialogState.Delete);
    } else if (event.currentTarget.id === "insert_trials") {
      setTrialStateDialog(TrialDialogState.Insert);
    }
  };

  const handleClose = () => {
    setTrialStateDialog(null);
  };

  function handleDeleteAllTrials() {
    apiService
      .deleteAllTrials()
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {});
  }

  function handleInsertTestTrial() {
    apiService
      .insertTestTrial()
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        window.location.reload(false);
      });
  }

  function fetchAllAccounts() {
    apiService.allAccounts().then((response) => {
      setAccounts(response.data);
    });
  }

  function fetchAllEmails() {
    apiService.allEmails().then((response) => {
      setWhitelistedEmails(response.data);
    });
  }

  function handleEmailAddChange(event) {
    setEmailToAdd(event.target.value);
  }

  function handleInsertEmail() {
    function closeDialog() {
      setEmailDialog(null);
      window.location.reload(false);
    }
    apiService
      .addemail({ email: emailToAdd })
      .then(() => {
        setEmailToAdd(null);

        const dialog = (
          <Dialog
            open
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The email address: {emailToAdd} was successfully added.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
        );
        setEmailDialog(dialog);
      })
      .catch((error) => {
        setEmailToAdd(null);
        const dialog = (
          <Dialog
            open
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The email address: {emailToAdd} failed to add: <br />
                {error.response.data.error_message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
        );
        setEmailDialog(dialog);
      });
  }

  function handleDeleteEmail(event) {
    apiService.deleteEmail({ email: event.currentTarget.id }).then(() => {
      window.location.reload(false);
    });
  }

  function handleDeleteAccount(event) {
    const account = accounts[event.currentTarget.id];
    apiService
      .deleteAccount({
        email: account.email,
        username: account.username || null,
      })
      .then(() => {
        window.location.reload(false);
      });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Title>Import Trial Data</Title>
          <UploadTrialDebugComponent {...props} />
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Title>Reset Trial Data</Title>
          <Button
            variant="outlined"
            id="delete_trials"
            onClick={handleClickOpen}
          >
            Delete All Trials
          </Button>
          <Dialog
            open={trialStateDialog === TrialDialogState.Delete}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete All Trial Data?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deleting trial data will remove all existing trials from the
                database. This operation cannot be undone.
                <br />
                Are you sure you want to proceed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleDeleteAllTrials} autoFocus>
                Delete all trials
              </Button>
            </DialogActions>
          </Dialog>
          <Button
            variant="outlined"
            id="insert_trials"
            onClick={handleClickOpen}
          >
            Insert All Trials
          </Button>
          <Dialog
            open={trialStateDialog === TrialDialogState.Insert}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Insert Fresh Trial Data?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Inserting fresh trial data will remove all existing trials, and
                replace them with a static file (possibly outdated).
                <br />
                Are you sure you want to proceed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleInsertTestTrial}>Proceed</Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {emailDialog}
          <Title>Whitelisted Emails</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {whitelistedEmails
                ? whitelistedEmails.map((email) => (
                    <TableRow key={email.email}>
                      <TableCell>{email.email}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={handleDeleteEmail}
                          id={email.email}
                        >
                          <DeleteIcon style={{ color: "red" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={emailToAdd}
                onChange={handleEmailAddChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                vertical-align="middle"
                onClick={handleInsertEmail}
              >
                Add Email
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Title>All Accounts</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Active</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts
                ? accounts.map((account) => (
                    <TableRow key={account.username}>
                      <TableCell>{account.username}</TableCell>
                      <TableCell>{account.email}</TableCell>
                      <TableCell>{account.is_active.toString()}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={handleDeleteAccount}
                          id={accounts.indexOf(account)}
                        >
                          <DeleteIcon style={{ color: "red" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
}
