import { Chip, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import FavoriteIconComponent from "../FavoriteIconComponent";
import { OnHoldStatusAlert, PendingStatusAlert } from "../StatusAlert";

export default function TrialRow(props) {
  const [isCellHovered, setIsCellHovered] = useState([false, null]);

  const trial = props.trial;

  function handleClick(row) {
    props.setSelectedTrial(row);
  }

  function handleCellHover(event, hover) {
    if (event.type === "mouseenter" && isCellHovered[1] === "touchend") {
      return;
    }
    setIsCellHovered([hover, event.type]);
  }

  const cellBackgroundColor = isCellHovered[0] ? grey[100] : null;

  let trialTitle = trial.nickname;
  if (trial.shorthand_name) {
    trialTitle = trialTitle + ` (${trial.shorthand_name})`;
  }

  let statusAlert = null;
  if (trial.status === "Hold") {
    statusAlert = <OnHoldStatusAlert abbreviated />;
  } else if (trial.status === "Pending") {
    statusAlert = <PendingStatusAlert abbreviated />;
  }

  return (
    <TableRow key={trial.id}>
      <TableCell
        onMouseEnter={(event) => handleCellHover(event, true)}
        onTouchStart={(event) => handleCellHover(event, true)}
        onMouseLeave={(event) => handleCellHover(event, false)}
        onTouchEnd={(event) => handleCellHover(event, false)}
        onClick={() => handleClick(trial)}
        sx={{
          padding: "6px 0px 6px 0px",
          backgroundColor: cellBackgroundColor,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "10px",
            cursor: "pointer",
          }}
        >
          <FavoriteIconComponent
            {...props}
            trial={trial}
            handleFavoriteTrialUpdated={props.handleFavoriteTrialUpdated}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textDecoration: isCellHovered[0] ? "underline" : null,
              }}
            >
              {trialTitle}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {trial.one_line_description}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {trial.is_best_match ? (
                <Chip label="Top Match" size="small" color="success" />
              ) : null}
              {trial.phase ? (
                <Chip label={trial.phase} size="small" color="info" />
              ) : null}
            </div>
          </div>
          {statusAlert}
        </div>
      </TableCell>
    </TableRow>
  );
}
