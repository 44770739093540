import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import NativeMultiSelect from "../NativeMultiSelect";

export default function ValidatedDropDownV2(props) {
  function valueSelected(e) {
    props.dropdownSelected(
      props.group.groupId,
      e.target.value !== "" ? e.target.value : null
    );
  }

  let items = [
    ...props.group.nodes.map((node) => {
      return {
        itemName: node.nodeName,
      };
    }),
  ];

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} error={props.value === null}>
      <InputLabel>{props.group.groupName}</InputLabel>
      <NativeMultiSelect
        name={props.group.groupId}
        label={props.group.groupName}
        value={props.value ?? "   "}
        onChange={valueSelected}
        items={items}
      />
    </FormControl>
  );
}
