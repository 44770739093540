const PageNames = {
  Search: {
    name: "Search",
    adminOnly: false,
  },
  Alltrials: {
    name: "All Trials",
    adminOnly: false,
  },
  InsertNewTrial: {
    name: "Add Trial",
    adminOnly: true,
  },
  Contacts: {
    name: "Contacts",
    adminOnly: false,
  },
  Debug: {
    name: "Debug Options",
    adminOnly: true,
  },
};

export default PageNames;
