import { Paper } from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import { useContext, useEffect, useState } from "react";
import useSessionStorage from "use-session-storage-state";
import { LoadingStatus } from "../../Helpers/LoadableObject";
import TrialTable from "../Dashboard/TrialTable";
import { ApiServiceContext } from "../../Context/GlobalContext";
import LoadingComponent from "../LoadingComponent";
import TrialSearchFormComponent from "./TrialSearchFormComponent";

export default function TrialSearchContainer(props) {
  const apiService = useContext(ApiServiceContext);
  const [cancerGroups, setCancerGroups] = useState(null);

  useEffect(() => {
    apiService.getAllSearchPaths().then((response) => {
      setCancerGroups(response.data);
      if (lastSubmittedModel) {
        searchTrials(lastSubmittedModel);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [trialsWrapper, setTrialsWrapper] = useState({
    loadingStatus: LoadingStatus.SUCCESS,
    trials: null,
  });

  const [searchModel, setSearchModel] = useSessionStorage("searchModel", {
    defaultValue: null,
  });

  const [lastSubmittedModel, setLastSubmittedModel] = useSessionStorage(
    "lastSubmittedModel",
    {
      defaultValue: null,
    }
  );

  function searchTrials(searchModel) {
    try {
      setLastSubmittedModel(searchModel);
      setTrialsWrapper({
        ...trialsWrapper,
        loadingStatus: LoadingStatus.LOADING,
      });
      apiService
        .searchTrials(searchModel[searchModel.length - 1].pathID)
        .then((response) => {
          setTrialsWrapper({
            loadingStatus: LoadingStatus.SUCCESS,
            trials: response.data.searchTrials,
          });
        })
        .catch(() => {
          setTrialsWrapper({
            loadingStatus: LoadingStatus.ERROR,
            trials: null,
          });
        });
    } catch (err) {
      setLastSubmittedModel(null);
      setTrialsWrapper({
        trials: null,
        loadingStatus: LoadingStatus.ERROR,
      });
    }
  }

  function handleFavoriteTrialUpdated(trial) {
    const trialIndex = trialsWrapper.trials.findIndex((t) => t.id === trial.id);
    const newTrials = [...trialsWrapper.trials];
    newTrials[trialIndex] = trial;
    trialsWrapper.trials = newTrials;

    setTrialsWrapper({
      ...trialsWrapper,
    });
  }

  const results = trialsWrapper.trials?.map((trial) => {
    return {
      ...trial,
      is_best_match: trial.num_paths <= 2,
    };
  });

  results?.sort(function compareFn(trial1, trial2) {
    if (trial1.is_best_match && !trial2.is_best_match) {
      return -1;
    }
    if (trial1.is_best_match && trial2.is_best_match) {
      return 0;
    }
    return 1;
  });

  const loadingComponent = (
    <Grid item xs={12}>
      <LoadingComponent />
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TrialSearchFormComponent
          {...props}
          searchModel={searchModel}
          setSearchModel={setSearchModel}
          searchTrials={searchTrials}
          cancerGroups={cancerGroups}
        />
      </Grid>
      {trialsWrapper.loadingStatus === LoadingStatus.LOADING ? (
        loadingComponent
      ) : (
        <>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <TrialTable
                {...props}
                handleFavoriteTrialUpdated={handleFavoriteTrialUpdated}
                title="Search Results"
                trials={results}
                setSelectedTrial={props.setSelectedTrial}
              ></TrialTable>
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
}
