import axios from "axios";

export default function TokenRefreshClient(tokenService) {
  const client = axios.create();

  client.interceptors.request.use(
    (config) => {
      config.baseURL = process.env.REACT_APP_API_HOSTNAME;
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${tokenService.getRefreshToken()}`,
      };
      return config;
    },
    (error) => Promise.reject(error)
  );

  async function updateAccessToken() {
    try {
      const rs = await client.post("/api/refreshtoken");
      const { access_token } = rs.data;
      tokenService.setTokens(access_token, tokenService.getRefreshToken());
    } catch (error) {
      // Refresh Token was expired - clear the token in memory and sign the user out
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 422)
      ) {
        tokenService.clearTokens();
        window.location.replace("/login");
      }
      throw error;
    }
  }

  return {
    updateAccessToken,
  };
}
