import Typography from "@mui/material/Typography";
import Title from "../Title";

export default function TextPairComponent(props) {
  return (
    <div>
      <Title>{props.title}</Title>
      <Typography variant="body1">{props.body}</Typography>
    </div>
  );
}
