import green from "@mui/material/colors/green";
import grey from "@mui/material/colors/grey";
import lightBlue from "@mui/material/colors/lightBlue";
import red from "@mui/material/colors/red";
import orange from "@mui/material/colors/orange";
import yellow from "@mui/material/colors/yellow";
import { createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";

export const appTheme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#C0DCF7",
            "&.Mui-focusVisible": { background: "#C0DCF7" },
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#C0DCF7",
            "&.Mui-focusVisible": { background: "#C0DCF7" },
          },
        },
      },
    },
    IconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: blue[50],
          },
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#1976d2",
    },
    // secondary: {
    //     light: red[500],
    //     main: red[700],
    //     dark: red[900],
    //     contrastText: grey[50]
    // },
    error: {
      light: red[400],
      main: red[500],
      dark: red[300],
      contrastText: grey[800],
    },
    success: {
      main: green[500],
      contrastText: grey[50],
    },
    warning: {
      main: yellow[500],
      contrastText: grey[800],
    },
    info: {
      main: orange[500],
    },
    text: {
      primary: grey[900],
      secondary: grey[600],
      disabled: grey[500],
      success: red[200],
    },
    action: {
      active: red[200],
      activeOpacity: 1,
      disabled: grey[700],
      disabledBackground: grey[200],
      hover: grey[100],
      hoverOpacity: 0.7,
      focus: red[600],
      focusOpacity: 1,
      // selected: red[300],
      selectedOpacity: 0,
    },
    background: {
      default: "#ffffffff",
      paper: "#ffffffff",
    },
    common: {
      black: grey[900],
      white: grey[200],
    },
    tonalOffset: 0.2,
  },
});
