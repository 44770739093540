import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Alert, AlertTitle } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Copyright from "Components/Copyright";

import { useContext, useState } from "react";
import { ApiServiceContext } from "Context/GlobalContext";
import { LoadingStatus } from "Helpers/LoadableObject";
import LoadingComponent from "Components/LoadingComponent";

export default function ResetPasswordComponent(props) {
  let [alertWrapper, setAlertWrapper] = useState({
    loadingStatus: null,
  });
  const apiService = useContext(ApiServiceContext);

  const handleSubmit = (event) => {
    setAlertWrapper({
      loadingStatus: LoadingStatus.LOADING,
      alert: <LoadingComponent />,
    });
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    apiService
      .resetPassword(data)
      .then((response) => {
        setAlertWrapper({
          loadingStatus: LoadingStatus.SUCCESS,
          alert: (
            <Alert severity="success">
              <AlertTitle></AlertTitle>
              If the email you entered is associated with an account, you'll
              receive an email with a link to reset your password
            </Alert>
          ),
        });
      })
      .catch((error) => {
        setAlertWrapper({
          loadingStatus: LoadingStatus.ERROR,
          alert: (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              An Error occurred during password reset:{" "}
              {error.response.data.error_message}
            </Alert>
          ),
        });
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {alertWrapper.alert}
          {alertWrapper.loadingStatus === LoadingStatus.LOADING ? null : (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                type="email"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Send password reset email
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
