import { Box, Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import AutocompletingTextField from "Components/InsertTrial/AutocompletingTextField";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../Title";

export default function InsertTrialSearchPage(props) {
  const navigate = useNavigate();
  const [nct, setNct] = useState(null);

  async function getTrialsForInput(input) {
    if (input === "") {
      return null;
    }
    const url =
      "https://clinicaltrials.gov/api/query/full_studies?expr=" +
      input +
      "&fmt=json";
    const fetchedData = await axios.get(url);
    const response = fetchedData.data;
    if (!response) {
      return null;
    }
    if (
      response["FullStudiesResponse"] &&
      response["FullStudiesResponse"]["FullStudies"]
    ) {
      const studies = response["FullStudiesResponse"]["FullStudies"];
      return studies
        .map((study) => {
          if (!study["Study"]) {
            return null;
          }
          if (!study["Study"]["ProtocolSection"]) {
            return null;
          }
          if (
            study["Study"]["ProtocolSection"]["IdentificationModule"] &&
            study["Study"]["ProtocolSection"]["IdentificationModule"]["NCTId"]
          ) {
            const nct =
              study["Study"]["ProtocolSection"]["IdentificationModule"][
                "NCTId"
              ];
            const title =
              study["Study"]["ProtocolSection"]["IdentificationModule"][
                "BriefTitle"
              ];
            return { label: nct, title: title };
          }
          return null;
        })
        .filter((o) => o != null);
    }
    return null;
  }

  function optionRow(props, option) {
    return (
      <Box {...props}>
        <div flexDirection="row">
          <Typography variant="subtitle1">{option.label}</Typography>
          <Typography variant="body2">{option.title}</Typography>
        </div>
      </Box>
    );
  }

  function valueUpdated(newValue) {
    setNct(newValue);
  }

  function handleInsertTrial() {
    navigate("/insertTrial/" + nct);
  }

  return (
    <InsertTrialSearchPageComponent
      handleInsertTrial={handleInsertTrial}
      getTrialsForInput={getTrialsForInput}
      optionRow={optionRow}
      valueUpdated={valueUpdated}
    />
  );
}

function InsertTrialSearchPageComponent(props) {
  return (
    <Paper
      sx={{
        p: "20px",
        display: "flex",
        flexDirection: "column",
        maxWidth: "350px",
        alignItems: "center",
        margin: "0px auto",
        justifyContent: "space-between",
        gap: "10px",
      }}
    >
      <Title
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Enter NCT Number
      </Title>
      <AutocompletingTextField
        textFieldLabel={"Enter Search Terms"}
        getOptionsForInput={props.getTrialsForInput}
        optionRow={props.optionRow}
        valueUpdated={props.valueUpdated}
      />
      <Button
        variant="contained"
        vertical-align="middle"
        onClick={props.handleInsertTrial}
      >
        Add Trial
      </Button>
    </Paper>
  );
}
