import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import TrialDetail from "./Dashboard/TrialDetail";

import Container from "@mui/material/Container";
import { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ApiServiceContext } from "../Context/GlobalContext";
import { LoadingStatus, createLoadableObject } from "../Helpers/LoadableObject";
import { AccountComponent } from "Components/Account";
import AthcreeAppBar from "./AthcreeAppBar";
import PageNames from "./Constants/PageNames";
import UserDetailNames from "./Constants/UserDetailNames";
import ContactsComponent from "./Contacts/ContactsComponent";
import Copyright from "./Copyright";
import DashboardContent from "./Dashboard/DashboardContent";
import DebugOptionsComponent from "./DebugOptionsComponent";
import InsertTrialSearchPage from "./InsertTrial/InsertTrialSearchPage";
import ModifyTrialPage from "./InsertTrial/ModifyTrialPage";
import TrialSearchContainer from "./Search/TrialSearchContainer";

function Root(props) {
  const dashboardPath = "dashboard";
  const searchPath = "search";
  const contactsPath = "contacts";
  const insertTrialPath = "insertTrial";
  const accountPath = "account";
  const trialDetailPath = "trialDetails";
  const debugPath = "debugoptions";

  const apiService = useContext(ApiServiceContext);
  let navigate = useNavigate();

  const [userobjectResult, setUserObjectResult] = useState(
    createLoadableObject()
  );

  useEffect(() => {
    apiService
      .basicuserinfo()
      .then((response) => {
        setUserObjectResult({
          loadingStatus: LoadingStatus.SUCCESS,
          object: response,
        });
      })
      .catch(() => {
        setUserObjectResult({
          loadingStatus: LoadingStatus.ERROR,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleAppBarItemSelected(name) {
    if (name === PageNames.Alltrials.name) {
      navigate("/" + dashboardPath);
    } else if (name === PageNames.InsertNewTrial.name) {
      navigate("/" + insertTrialPath);
    } else if (name === PageNames.Contacts.name) {
      navigate("/" + contactsPath);
    } else if (name === PageNames.Search.name) {
      sessionStorage.removeItem("searchModel");
      sessionStorage.removeItem("lastSubmittedModel");
      navigate("/" + searchPath);
    } else if (name === UserDetailNames.Logout) {
      apiService.logout().then((response) => {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
      });
    } else if (name === UserDetailNames.Account) {
      navigate("/" + accountPath);
    } else if (name === PageNames.Debug.name) {
      navigate("/" + debugPath);
    }
  }

  function handleTrialSelected(trial) {
    navigate("/" + trialDetailPath + "/" + trial.id);
  }

  if (userobjectResult.loadingStatus === LoadingStatus.LOADING) {
    return null;
  }

  const commonProps = {
    ...props,
    userobject: userobjectResult.object,
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AthcreeAppBar
        {...commonProps}
        handleAppbarItemClick={handleAppBarItemSelected}
      />
      <Container maxWidth="lg">
        <div style={{ marginTop: 80 }} />
        <Routes>
          <Route
            path="/"
            element={<Navigate to={searchPath} replace={true} />}
          />
          <Route
            path={dashboardPath}
            element={
              <DashboardContent
                {...commonProps}
                setSelectedTrial={handleTrialSelected}
              />
            }
          />
          <Route
            path={contactsPath}
            element={<ContactsComponent {...commonProps} />}
          />
          <Route
            exact
            path={insertTrialPath + "/*"}
            element={<ModifyTrialPage {...commonProps} />}
          />
          <Route
            exact
            path="modifyTrial"
            element={<ModifyTrialPage {...commonProps} />}
          />
          <Route
            exact
            path={insertTrialPath}
            element={<InsertTrialSearchPage {...commonProps} />}
          />
          <Route
            path={trialDetailPath + "/*"}
            element={<TrialDetail {...commonProps} />}
          />
          <Route
            path={searchPath}
            element={
              <TrialSearchContainer
                {...commonProps}
                setSelectedTrial={handleTrialSelected}
              />
            }
          />
          <Route
            path={accountPath}
            element={<AccountComponent {...commonProps} />}
          />
          <Route
            path={debugPath}
            element={<DebugOptionsComponent {...commonProps} />}
          />
          <Route
            path="/*"
            element={<Navigate to={searchPath} replace={true} />}
          />
        </Routes>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    </Box>
  );
}

export default function RootContent(props) {
  return <Root {...props} />;
}
