import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Alert, AlertTitle } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Copyright from "../Copyright";

import { useContext, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { ApiServiceContext } from "../../Context/GlobalContext";

export default function UpdatePasswordComponent(props) {
  const apiService = useContext(ApiServiceContext);
  const [urlParams] = useSearchParams();
  const [alert, setAlert] = useState(null);

  const [password, setPassword] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [errors, setErrors] = useState({});
  const [didSubmit, setDidSubmit] = useState(false);

  const token = urlParams.get("token");
  if (token === null) {
    return <Navigate to="/login" replace />;
  }

  const handleSubmit = (event) => {
    setDidSubmit(true);
    if (Object.values(errors).filter((value) => value === true).length !== 0) {
      return;
    }

    event.preventDefault();
    const data = {
      password: password,
      token: token,
    };
    apiService
      .updatePassword(data)
      .then((response) => {
        setAlert(
          <Alert severity="success">
            <AlertTitle></AlertTitle>
            Password has been updated
          </Alert>
        );
      })
      .catch((error) => {
        setAlert(
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            An Error occurred changing password:{" "}
            {error.response.data.error_message}
          </Alert>
        );
      });
  };

  function validate(password, password2) {
    const updatedErrors = JSON.parse(JSON.stringify(errors));
    let hasPassword = false;
    if (password !== null && password !== undefined && password.length > 0) {
      const matching_pass = password === password2;
      updatedErrors.matching_pass = !matching_pass;
      hasPassword = true;
    } else if (
      // No password
      password2 !== null &&
      password2 !== undefined &&
      password2.length > 0
    ) {
      const matching_pass = password === password2;
      updatedErrors.matching_pass = !matching_pass;
    } else {
      const matching_pass = password === password2;
      updatedErrors.matching_pass = !matching_pass;
    }

    updatedErrors.pass = !hasPassword;
    setErrors(updatedErrors);
  }

  function onChange(e) {
    if (e.target.name === "password") {
      setPassword(e.target.value);
      validate(e.target.value, password2);
    } else {
      setPassword2(e.target.value);
      validate(password, e.target.value);
    }
  }

  let passwordError = false;
  if (didSubmit) {
    passwordError = errors.pass;
  }

  const matching_pass = errors.matching_pass;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change password
        </Typography>
        <Box sx={{ mt: 1 }}>
          {alert}
          <TextField
            margin="normal"
            fullWidth
            id="password"
            label="New Password"
            name="password"
            autoComplete="password"
            type="password"
            autoFocus
            onChange={onChange}
            color={passwordError ? "error" : null}
            error={passwordError ? true : false}
            helperText={passwordError ? "A password is required" : null}
          />
          <TextField
            margin="normal"
            fullWidth
            id="password2"
            label="Confirm New Password"
            name="password2"
            autoComplete="password"
            type="password"
            autoFocus
            onChange={onChange}
            color={matching_pass ? "error" : null}
            error={matching_pass ? true : false}
            helperText={matching_pass ? "Passwords must match" : null}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmit}
          >
            Update Password
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
