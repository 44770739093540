import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { CircularProgress, TableCell, TableRow } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import AlertDialogModalComponent from "Components/AlertDialogModalComponent";
import { useContext, useState } from "react";
import { ApiServiceContext } from "../../Context/GlobalContext";

export default function ContactsComponentRow(props) {
  const [contact, setContact] = useState(props.contact ? props.contact : null);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleError, setVisibleError] = useState(null);
  const apiService = useContext(ApiServiceContext);

  function handleDialogClose() {
    setVisibleError(null);
  }

  function textFieldChanged(e) {
    const field = e.target.id;
    const newValue = e.target.value;
    let newContact = { ...contact };
    newContact[field] = newValue;
    setContact(newContact);
  }

  function saveOrCreatePressed() {
    setIsLoading(true);
    apiService
      .updateContact(contact, props.siteID)
      .then((response) => {
        setIsLoading(false);
        props.contactUpdated(
          response.data.updateContact.contact,
          props.newContact
        );
      })
      .catch((error) => {
        setIsLoading(false);
        setContact(props.contact ? props.contact : null);
        if (error.message) {
          setVisibleError(error.message);
        } else {
          setVisibleError(JSON.stringify(error));
        }
      });
  }

  function deletePressed() {
    setIsLoading(true);
    apiService
      .deleteContact(contact.id)
      .then(() => {
        setIsLoading(false);
        props.contactDeleted(contact);
      })
      .catch((error) => {
        setIsLoading(false);
        setContact(props.contact ? props.contact : null);
        if (error.message) {
          setVisibleError(error.message);
        } else {
          setVisibleError(JSON.stringify(error));
        }
      });
  }

  const generateSaveOrCreateColumn = () => {
    if (!props.editable) {
      return null;
    }
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    if (props.newContact) {
      return (
        <IconButton color="primary" size="small" onClick={saveOrCreatePressed}>
          <AddIcon />
        </IconButton>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <IconButton color="primary" size="small" onClick={saveOrCreatePressed}>
          <SaveIcon />
        </IconButton>
        <IconButton color="primary" size="small" onClick={deletePressed}>
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  const saveOrCreateColumn = generateSaveOrCreateColumn();

  if (visibleError) {
    return (
      <AlertDialogModalComponent
        open={visibleError !== null}
        onClose={handleDialogClose}
        error={visibleError}
      />
    );
  }

  return (
    <TableRow>
      <TableCell key="name">
        {props.editable ? (
          <TextField
            id="name"
            variant="outlined"
            value={contact?.name ? contact?.name : ""}
            size="small"
            onChange={textFieldChanged}
          />
        ) : (
          contact.name
        )}
      </TableCell>
      <TableCell key="email">
        {props.editable ? (
          <TextField
            id="email"
            variant="outlined"
            value={contact?.email ? contact?.email : ""}
            size="small"
            onChange={textFieldChanged}
          />
        ) : (
          contact.email
        )}
      </TableCell>
      <TableCell key="phone" align="left">
        {props.editable ? (
          <TextField
            id="phone"
            variant="outlined"
            value={contact?.phone ? contact?.phone : ""}
            size="small"
            onChange={textFieldChanged}
          />
        ) : (
          contact.phone
        )}
      </TableCell>
      {!saveOrCreateColumn ? null : (
        <TableCell key="save" align="right">
          {saveOrCreateColumn}
        </TableCell>
      )}
    </TableRow>
  );
}
