import { Star, StarOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { blue, yellow } from "@mui/material/colors";
import * as React from "react";
import { useContext, useState } from "react";
import { ApiServiceContext } from "../Context/GlobalContext";

export default function FavoriteIconComponent(props) {
  const [isFavHovered, setIsFavHovered] = useState([false, null]);
  const apiService = useContext(ApiServiceContext);

  function handleFavHover(event, hover) {
    if (event.type === "mouseenter" && isFavHovered[1] === "touchend") {
      return;
    }
    if (event.type === "touchstart") {
      event.stopPropagation();
    }
    setIsFavHovered([hover, event.type]);
  }

  function handleSetFavorite(e, trial) {
    e.stopPropagation();

    const trial_update = {
      id: trial.id,
      number: trial.number,
      is_favorite: !trial.is_favorite,
    };
    const new_trial = { ...trial };
    new_trial.is_favorite = trial_update.is_favorite;

    apiService.updateTrial({ trial: trial_update, is_new: false }).then(() => {
      props.handleFavoriteTrialUpdated(new_trial);
    });
  }

  const favBackgroundColor = isFavHovered[0] ? blue[50] : null;

  return (
    <IconButton
      onMouseEnter={(event) => handleFavHover(event, true)}
      onTouchStart={(event) => handleFavHover(event, true)}
      onMouseLeave={(event) => handleFavHover(event, false)}
      onTouchEnd={(event) => handleFavHover(event, false)}
      onClick={(event) => handleSetFavorite(event, props.trial)}
      size="small"
      sx={{
        backgroundColor: favBackgroundColor,
        marginLeft: "6px",
        "&:hover": { backgroundColor: favBackgroundColor },
      }}
    >
      {props.trial.is_favorite ? (
        <Star
          sx={{
            color: yellow[700],
          }}
        />
      ) : (
        <StarOutline
          sx={{
            color: yellow[700],
          }}
        />
      )}
    </IconButton>
  );
}
