import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import Title from "Components/Title";
import { useContext, useState } from "react";
import { ApiServiceContext } from "../Context/GlobalContext";
import DeleteIcon from "@mui/icons-material/Delete";

function CommentItem(props) {
  return (
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item>
        <Avatar alt="Remy Sharp" src="" />
      </Grid>
      <Grid justifyContent="left" item xs zeroMinWidth>
        <h4 style={{ margin: 0, textAlign: "left" }}>{props.author_name}</h4>
        <p style={{ textAlign: "left" }}>{props.content}</p>
        <p style={{ textAlign: "left", color: "gray" }}>
          Posted on: {props.publish_time}
        </p>
      </Grid>
      {props.is_author ? (
        <Grid justifyContent="left" item xs zeroMinWidth>
          <IconButton onClick={props.handleDeleteComment} id={props.comment_id}>
            <DeleteIcon style={{ color: "red" }} />
          </IconButton>
        </Grid>
      ) : null}
    </Grid>
  );
}

function CommentsContainer(props) {
  if (!props.comments) {
    return null;
  }

  return (
    <>
      {props.comments.map((c) => (
        <div key={props.comments.indexOf(c)}>
          <CommentItem
            content={c.content}
            publish_time={c.publish_time}
            author_name={c.author_name}
            is_author={c.is_author}
            handleDeleteComment={props.handleDeleteComment}
            comment_id={c.id}
          />
          {props.comments.indexOf(c) !== props.comments.length - 1 ? (
            <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
          ) : null}
        </div>
      ))}
    </>
  );
}

function AddCommentItem(props) {
  return (
    <>
      <TextField
        fullWidth
        placeholder="Add a comment"
        value={props.value ?? ""}
        onChange={props.onChange}
      />
      <Button variant="contained" onClick={props.onClick}>
        Submit
      </Button>
    </>
  );
}

export default function CommentsComponent(props) {
  const apiService = useContext(ApiServiceContext);
  const [addCommentContent, setAddCommentContent] = useState(null);

  const TitleBar = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <IconButton color="primary" size="small" onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
      <Title
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        Comments
      </Title>
    </div>
  );

  async function submitComment() {
    await apiService.addComment(props.trial_id, addCommentContent);
    await props.commentsUpdated();
    setAddCommentContent(null);
  }

  async function deleteComment(e) {
    await apiService.deleteComment(e.currentTarget.id);
    await props.commentsUpdated();
  }

  return (
    <div>
      <TitleBar />
      <Paper style={{ padding: "40px 20px" }}>
        <CommentsContainer
          comments={props.comments}
          handleDeleteComment={deleteComment}
        />
      </Paper>
      <AddCommentItem
        onClick={submitComment}
        value={addCommentContent}
        onChange={(e) => {
          setAddCommentContent(e.target.value);
        }}
      />
    </div>
  );
}
