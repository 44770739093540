import Select from "@mui/material/Select";
export default function NativeMultiSelect(props) {
  let items = [...props.items];
  items.unshift({
    itemName: "",
    itemDescription: null,
  });
  return (
    <Select
      native
      displayEmpty
      autoWidth
      id={props.id}
      name={props.name}
      value={props.value ?? ""}
      label={props.label}
      onChange={props.onChange}
    >
      {items.map((p) => {
        return (
          <option key={p.itemName} value={p.itemName}>
            {p.itemDescription
              ? p.itemName + ": " + p.itemDescription
              : p.itemName}
          </option>
        );
      })}
    </Select>
  );
}
