import { Typography, CircularProgress } from "@mui/material";

export default function LoadingComponent(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography>Loading...</Typography>
      <CircularProgress />
    </div>
  );
}
