export const LoadingStatus = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

export function createLoadableObject() {
  return {
    loadingStatus: LoadingStatus.LOADING,
  };
}
