import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

function compareContacts(contactOne, contactTwo) {
  const nameOneComponents = contactOne.name.split(" ");
  const nameTwoComponents = contactTwo.name.split(" ");

  const compareNameOne =
    nameOneComponents.length > 1 ? nameOneComponents[1] : contactOne.name;
  const compareNameTwo =
    nameTwoComponents.length > 1 ? nameTwoComponents[1] : contactTwo.name;

  return compareNameOne.localeCompare(compareNameTwo);
}

export default function ContactPickerComponent(props) {
  const sortedContacts = [...props.site.contacts].sort(compareContacts);
  const selectedContactIDs = props.selectedContacts
    ? props.selectedContacts.map((contact) => contact.id)
    : [];
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">{props.site.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <FormGroup>
            {sortedContacts.map((contact) => {
              return (
                <FormControlLabel
                  key={sortedContacts.indexOf(contact)}
                  control={
                    <Checkbox
                      id={contact.id}
                      checked={selectedContactIDs.includes(contact.id)}
                      onChange={props.handleContactChange}
                    />
                  }
                  label={contact.name}
                />
              );
            })}
          </FormGroup>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
