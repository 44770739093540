import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";

export default function AutocompletingTextField(props) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);

  async function textFieldChanged(e) {
    const textInput = e.target.value;
    valueChanged(textInput);
    const optionsForInput = await props.getOptionsForInput(e.target.value);
    setOptions(optionsForInput ?? []);
  }

  function valueChanged(e) {
    setValue(e);
    props.valueUpdated(e);
  }

  return (
    <Autocomplete
      fullWidth
      freeSolo
      disableClearable
      filterOptions={(x) => x}
      options={options}
      value={value}
      onChange={(event, newValue) => valueChanged(newValue.label)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.textFieldLabel}
          onChange={textFieldChanged}
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
      renderOption={(innerprops, option) => props.optionRow(innerprops, option)}
    />
  );
}
