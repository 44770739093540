import { ArrowBackIos } from "@mui/icons-material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CommentIcon from "@mui/icons-material/Comment";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PersonIcon from "@mui/icons-material/Person";
import { IconButton, TableBody, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ApiServiceContext,
  TokenServiceContext,
} from "../../Context/GlobalContext";
import getUsername from "../../Helpers/UserInfoConverter";
import FavoriteIconComponent from "../FavoriteIconComponent";
import Item from "../Item";
import LoadingComponent from "../LoadingComponent";
import { OnHoldStatusAlert, PendingStatusAlert } from "../StatusAlert";
import Title from "../Title";
import ContactInfoRow from "./ContactInfoRow";
import TextPairComponent from "./TextPairComponent";

import Drawer from "@mui/material/Drawer";
import CommentsComponent from "Components/CommentsComponent";

export default function TrialDetail(props) {
  const tokenService = useContext(TokenServiceContext);
  const apiService = useContext(ApiServiceContext);

  let location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTrial, setSelectedTrial] = useState(null);
  const [allSites, setSites] = useState(null);
  const [commentsOpen, setCommentsOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    Promise.all([fetchTrialId(), getAllSites()])
      .then((responses) => {
        setSelectedTrial(responses[0].data.getTrial);
        setSites(responses[1].data.listSites);
        setIsLoading(false);
      })
      .catch((error) => {
        setSelectedTrial(null);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchTrialId() {
    const pathElements = location.pathname.split("/");
    return apiService.getTrial(pathElements[2]);
  }

  function getAllSites() {
    return apiService.getAllSites();
  }

  async function handleFavoriteTrialUpdated(trial) {
    const response = await fetchTrialId();
    setSelectedTrial(response.data.getTrial);
  }

  function handleBackClicked() {
    navigate(-1);
  }

  function handleEditClicked() {
    navigate("/modifyTrial", {
      state: {
        trialId: selectedTrial.id,
      },
    });
  }

  function toggleComments() {
    setCommentsOpen(!commentsOpen);
  }

  function handleCommentsClicked() {
    toggleComments();
  }

  function handleEmailClicked(contact) {
    apiService
      .sendTrialEmail({
        trial_id: selectedTrial.id,
        contact_email: contact.email,
      })
      .then(() => {
        const disclaimerText =
          "NOTE: The user is responsible for making sure that no PHI is sent through an email that is not a Froedtert or MCW email both on the part of the sender and the receiver." +
          "The user agrees to this responsibility." +
          "If there is any doubt, do not include PHI in this email.";

        const emailBody = [
          "Dear " + contact.name,
          "I have a patient who is a candidate for this trial:",
          selectedTrial.formal_title,
          "MRN number is: ",
          "The patient is interested. Can you reach out to the patient to see if we can proceed with screening and possible enrollment?",
          "Thank you so much",
          "Sincerely, ",
          username,
          disclaimerText,
        ].join("%0D%0A%0D%0A");
        window.location = `mailto:${contact.email}?subject=Trial Candidate&body=${emailBody}`;
      });
  }

  async function handleCommentsUpdated() {
    const response = await fetchTrialId();
    setSelectedTrial(response.data.getTrial);
  }

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (selectedTrial === null) {
    return;
  }

  const username = getUsername(props.userobject);

  const trialPhoneProp = function (phone) {
    return <a href={"tel:" + phone}>{phone}</a>;
  };

  const trialEmailProp = function (contact) {
    return (
      <Typography
        variant="body1"
        onClick={() => handleEmailClicked(contact)}
        sx={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
      >
        {contact.email}
      </Typography>
    );
  };

  const criteriaElement = (
    <div>
      <Title>Criteria</Title>
      <Table size="small">
        <TableBody>
          {selectedTrial.criteria.map((row) => (
            <TableRow key={row.criteria}>
              <TableCell>{row.criteria}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );

  const contactIDs = selectedTrial.contacts.map((contact) => contact.id);
  const openSites = allSites.filter((site) => {
    return site.contacts.some((c) => {
      return contactIDs.includes(c.id);
    });
  });

  const sitesElement = (
    <div>
      <Title>Open Sites</Title>
      <Table size="small">
        <TableBody>
          {openSites.map((row) => (
            <TableRow key={row.name}>
              <TableCell>{row.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );

  let siteNameForContactId = {};
  for (let site of allSites) {
    for (let contact of site.contacts) {
      siteNameForContactId[contact.id] = site.name;
    }
  }

  const contactsElement =
    selectedTrial.contacts.length === 0
      ? null
      : selectedTrial.contacts.map((contact) => (
          <div>
            <Title>
              {siteNameForContactId[contact.id]} Trial Coordinator Information
            </Title>
            <Table size="small">
              <TableBody>
                <ContactInfoRow
                  key="name"
                  iconComponent={<PersonIcon vertical-align="middle" />}
                  itemProp={
                    <Typography variant="body1">{contact.name}</Typography>
                  }
                />
                <ContactInfoRow
                  key="phone"
                  iconComponent={<ContactPhoneIcon vertical-align="middle" />}
                  itemProp={trialPhoneProp(contact.phone)}
                />
                <ContactInfoRow
                  key="email"
                  iconComponent={<AlternateEmailIcon vertical-align="middle" />}
                  itemProp={trialEmailProp(contact)}
                />
              </TableBody>
            </Table>
          </div>
        ));

  const roles = tokenService.getRoles();
  const is_admin = roles ? roles.includes("admin") : false;
  let headerText = selectedTrial.nickname;
  if (selectedTrial.shorthand_name) {
    headerText = headerText + ` (${selectedTrial.shorthand_name})`;
  }

  let statusAlert = null;
  if (selectedTrial.status === "Hold") {
    statusAlert = <OnHoldStatusAlert fontSize={20} flex={1} />;
  } else if (selectedTrial.status === "Pending") {
    statusAlert = <PendingStatusAlert fontSize={20} flex={1} />;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 8,
          ...props.sx,
        }}
      >
        {statusAlert}
        {
          <Drawer
            anchor="right"
            PaperProps={{ style: { width: "50%" } }}
            open={commentsOpen}
            onClose={toggleComments}
          >
            <Box>
              <CommentsComponent
                trial_id={selectedTrial.id}
                onClose={toggleComments}
                comments={selectedTrial.comments}
                commentsUpdated={handleCommentsUpdated}
              />
            </Box>
          </Drawer>
        }
      </div>
      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
        <Item sx={{ gridRow: "1", gridColumn: "span 2" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <IconButton
              color="primary"
              size="small"
              onClick={handleBackClicked}
            >
              <ArrowBackIos />
            </IconButton>
            <FavoriteIconComponent
              {...props}
              trial={selectedTrial}
              handleFavoriteTrialUpdated={handleFavoriteTrialUpdated}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              <Typography variant="body1">{headerText}</Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                fontSize="11px"
              >
                {selectedTrial.trial_views - 1} other users viewed this trial
              </Typography>
            </div>
            {is_admin ? (
              <IconButton
                size="small"
                onClick={handleEditClicked}
                color="primary"
                sx={{
                  marginLeft: "auto",
                  marginRight: 0,
                }}
              >
                <ModeEditIcon />
              </IconButton>
            ) : null}
            {
              <IconButton
                size="small"
                onClick={handleCommentsClicked}
                color="primary"
                sx={{
                  marginRight: 0,
                }}
              >
                <CommentIcon />
              </IconButton>
            }
          </div>
        </Item>
        <Item sx={{ gridRow: "2", gridColumn: "span 2" }}>
          {
            <TextPairComponent
              title="What is this trial?"
              body={selectedTrial.one_line_description}
            />
          }
        </Item>
        <Item sx={{ gridRow: "3", gridColumn: "span 2" }}>
          {
            <TextPairComponent
              title="Simple Description"
              body={selectedTrial.simple_description}
            />
          }
        </Item>
        <Item sx={{ gridRow: "4", gridColumn: "span 2" }}>
          {selectedTrial.sponsor ? (
            <TextPairComponent
              title="Sponsor"
              body={selectedTrial.sponsor.sponsor}
            />
          ) : null}
        </Item>
        <Item sx={{ gridRow: "5", gridColumn: "span 2" }}>
          {selectedTrial.characteristics ? (
            <TextPairComponent
              title="Characteristics Of Treatment Being Tested"
              body={selectedTrial.characteristics}
            />
          ) : null}
        </Item>
        <Item sx={{ gridRow: "6", gridColumn: "span 2" }}>
          {criteriaElement}
        </Item>
        <Item sx={{ gridRow: "7", gridColumn: "span 2" }}>{sitesElement}</Item>
        {contactsElement
          ? contactsElement.map((item) => (
              <Item
                sx={{
                  gridRow: "9" + contactsElement.indexOf(item),
                  gridColumn: "span 2",
                }}
              >
                {item}
              </Item>
            ))
          : null}
      </Box>
    </div>
  );
}
