import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ApiServiceContext } from "Context/GlobalContext";
import { LoadingStatus, createLoadableObject } from "Helpers/LoadableObject";
import LoadingComponent from "Components/LoadingComponent";

export default function AccountComponent(props) {
  const [accountDetails, setAccountDetails] = useState(createLoadableObject());
  const apiService = useContext(ApiServiceContext);

  useEffect(() => {
    apiService.getAccountDetails().then((result) => {
      setAccountDetails({
        loadingStatus: LoadingStatus.SUCCESS,
        value: result.data.currentUser,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (accountDetails.loadingStatus === LoadingStatus.LOADING) {
    return <LoadingComponent />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minWidth: "300px",
        maxWidth: "700px",
        margin: "auto",
      }}
    >
      <Typography variant="h6" align="center" color="primary">
        Account Details
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow key={0}>
              <TableCell component="th" scope="row" color="primary">
                First Name
              </TableCell>
              <TableCell align="right">
                {accountDetails.value.first_name}
              </TableCell>
            </TableRow>
            <TableRow key={1}>
              <TableCell component="th" scope="row">
                Last Name
              </TableCell>
              <TableCell align="right">
                {accountDetails.value.last_name}
              </TableCell>
            </TableRow>
            <TableRow key={2}>
              <TableCell component="th" scope="row">
                Email
              </TableCell>
              <TableCell align="right">{accountDetails.value.email}</TableCell>
            </TableRow>
            <TableRow key={3}>
              <TableCell component="th" scope="row">
                Date Registered
              </TableCell>
              <TableCell align="right">
                {accountDetails.value.date_registered}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
